import React, { useState } from 'react'
import tw, { css } from 'twin.macro'
import { lg, xl } from '../utils/breakpoints'
import Link from './link'
import Button from './button'
import Text from './text'

const ActionNavItem = ({ action, headerStyle, subtitleStyle, textStyle, titleId, subtitleId, descriptionId }) => {
    const [isHover, setHover] = useState(false);
    return (
      <Link
        key={action?.title}
        to={action?.callToAction.link}
        style={tw`relative border-white border-opacity-20 border-b last-of-type:border-b-0 lg:(flex-equal border-l border-b-0)`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div
          css={css`
            ${tw`hidden`}
            ${lg} {
              ${tw`h-full flex-col items-start px-10 pt-6 pb-8 pointer-events-none opacity-0`}
              ${isHover && tw`flex`}
            }
            ${xl} {
              ${tw`px-12`}
            }
          `}
        >
          <div>
            <Text id={titleId} content={action?.title} style={headerStyle} />
            <Text
              id={subtitleId}
              content={action?.subtitleNode}
              style={[subtitleStyle, textStyle, tw`font-medium`]}
            />
          </div>
          <Button type="secondary" label={action?.callToAction?.label} style={tw`lg:mt-6`} />
        </div>
        <div
          css={[
            css`
              ${tw`relative flex items-center justify-between pt-6 pb-7`}
              ${lg} {
                ${tw`h-full flex-col items-start px-10 pt-6 pb-8 cursor-pointer transition-colors duration-500 ease-in-out`}
              }
              ${xl} {
                ${tw`px-12`}
              }
            `,
            isHover &&
              css`
                ${lg} {
                  ${tw`bg-secondary-500 absolute top-0 -bottom-8 pt-6 pb-16 h-auto w-full`}
                  div#${titleId}-placeholder, div#${subtitleId}-placeholder, div#${descriptionId}-placeholder {
                    ${tw`text-primary-500`}
                  }
                  div#${descriptionId}-placeholder {
                    ${tw`block text-opacity-75 mt-6`}
                  }
                  a,
                  button {
                    ${tw`bg-primary-500 border-primary-500 transition-colors duration-500 ease-in-out`}
                    svg {
                      ${tw`text-white fill-white transition-colors duration-500 ease-in-out`}
                    }
                  }
                }
              `,
          ]}
        >
          <div>
            <Text id={`${titleId}-placeholder`} content={action?.title} style={headerStyle} />
            <Text
              id={`${subtitleId}-placeholder`}
              content={action?.subtitleNode}
              style={[subtitleStyle, textStyle, tw`font-medium`]}
            />
          </div>
          <Text
            id={`${descriptionId}-placeholder`}
            content={action?.descriptionNode}
            style={[textStyle, tw`hidden`]}
          />
          <Button type="secondary" label={action?.callToAction?.label} style={tw`lg:mt-6`} />
        </div>
      </Link>
    )
  }

  export default ActionNavItem;