import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="bottom-left" width={28} height={92}>
        <Rectangle x={0} y={0} width={28} height={28} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="right" width={758} height={168}>
        <Rectangle x={0} y={140} width={28} height={28} fill="navy" />
        <Rectangle x={28} y={84} width={112} height={56} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="bottom-right" width={168} height={260}>
        <Rectangle x={0} y={0} width={168} height={28} fill="yellow" />
        <Rectangle x={112} y={28} width={56} height={56} fill="navy" />
      </Overlay>
    </>
  ),
}
