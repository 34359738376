import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { lg } from '../utils/breakpoints'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Link from './link'
import Text from './text'

const CardList = ({ cards }) => {
  return (
    <div css={tw`flex flex-col lg:(grid gap-12 grid-cols-card-list justify-center) xl:gap-28`}>
      {cards.map((card, i) => (
        <Link
          key={card.title}
          to={card.callToAction.link}
          style={tw`pt-10 first-of-type:pt-0 lg:pt-0`}
        >
          <div
            css={css`
              ${tw`flex flex-row lg:(h-full flex-col items-center cursor-pointer)`}
              &:hover {
                > div:first-of-type {
                  ${lg} {
                    transform: translateY(-0.5rem);
                  }
                }
            `}
          >
            <div
              css={css`
                ${tw`flex-none mr-6 lg:mr-0`}
                width: 3rem;
                height: 3rem;

                ${lg} {
                  ${tw`mb-12 transition-transform duration-300 ease-in-out`}
                  width: 6.25rem;
                  height: 6.25rem;
                }
              `}
            >
              <Image image={card.image} />
            </div>
            <div
              css={[
                tw`flex flex-col flex-grow items-start border-b border-line-grey pb-10
              lg:(pb-0 border-b-0 items-center justify-between)`,
                i === cards.length - 1 && tw`border-b-0 pb-0`,
              ]}
            >
              <div>
                <Heading
                  headingType="h4"
                  content={card.title}
                  style={tw`font-bold text-primary-500 mt-1 lg:(mt-0 text-center)`}
                />
                <Text content={card.descriptionNode} style={tw`mt-4 lg:text-center`} />
              </div>
              <Button
                type="primary"
                size="base"
                label={card.callToAction[0].label}
                theme="yellow-navy"
                style={tw`mt-6 lg:mt-19`}
              />
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

CardList.propTypes = {
  cards: PropTypes.array,
}

export default CardList
