import kebabCase from 'lodash/kebabCase'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { fluid } from '../styles/typography'
import { lg, xl } from '../utils/breakpoints'
import { HTMLType, LinkType } from '../utils/prop-types'
import Button from './button'
import Link from './link'
import Text from './text'
import ActionNavItem from './action-nav-item'

const ActionNav = ({ header, actions }) => {
  const headerStyle = css`
    ${tw`font-medium text-white`}
    ${fluid(24, 32)}
    line-height: 32px;
    ${lg} {
      line-height: 40px;
    }
  `
  const subtitleStyle = tw`mt-4 text-secondary-500`

  const textStyle = css`
    ${fluid(14, 16)}
  `

  return (
    <div css={tw`bg-primary-500`}>
      <div css={[global`layout.container`, tw`flex flex-col py-8 lg:(flex-row pt-4 pb-0)`]}>
        <div
          css={tw`relative flex flex-col items-start border-white border-opacity-20 border-b pb-8 lg:(flex-equal border-b-0 pt-6)`}
        >
          <Text content={header.titleNode} style={headerStyle} />
          <Text content={header.subtitleNode} style={[subtitleStyle, textStyle]} />
        </div>
        {actions?.map((action, i) => {
          const el = kebabCase(action?.title)
          const [titleId, subtitleId, descriptionId] = [
            `${el}-title`,
            `${el}-subtitle`,
            `${el}-description`,
          ]
          return (
            <ActionNavItem 
                key={i} 
                action={action} 
                headerStyle={headerStyle}
                subtitleStyle={subtitleStyle}
                textStyle={textStyle}
                titleId={titleId}
                subtitleId={subtitleId}
                descriptionId={descriptionId}
            />
          )
        })}
      </div>
    </div>
  )
}

ActionNav.propTypes = {
  header: PropTypes.shape({ titleNode: HTMLType, subtitleNode: HTMLType }).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: HTMLType,
      subtitleNode: HTMLType,
      descriptionNode: HTMLType,
      callToAction: LinkType,
    })
  ).isRequired,
}

export default ActionNav
